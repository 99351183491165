
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import './Modal-geracao.css';
import { useEffect, useState } from "react";
import api from "../../api";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import loadingGif from '../../img/loading.gif';
import { toast } from 'react-toastify';

function ModalGeracao({ statusModal, onCloseModalGeracao, operacao, emitirNFSe, registrosSelected, gerarBoletos }) {

    const [emitentes, setEmitentes] = useState([]);
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [mes, setMes] = useState('escolha');
    const [loading, setLoading] = useState(false);
    const [cidades, setCidades] = useState("");
    const [servicos, setServicos] = useState("");
    const [selectedCidade, setSelectedCidade] = useState(0);
    const [selectedServico, setSelectedServico] = useState(0);
    const [descricao, setDescricao] = useState("");
    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR');
    const [data,] = useState(dataFormatada);

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const handleClose = () => {
        setSelectedEmitente(0);
        setLoading(false);
        setMes("escolha");
        setDescricao("");
        setSelectedCidade(0);
        setSelectedServico(0);
        onCloseModalGeracao();
    }

    const handleMes = (e) => {
        setMes(e.target.value);
    }

    const handleGerar = async () => {
        if (selectedEmitente && mes) {
            setLoading(true);
            if (operacao === 'Geracao') {
                try {
                    const { data: gerando } = await api.post("/faturamento/gerar/" + mes + "/" + selectedEmitente, {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                    toast.success('Faturamento gerado com sucesso...');
                    handleClose();
                } catch (error) {
                    toast.error("Problema na geração: " + error);
                    handleClose();
                }
            }
            if (operacao === 'Boleto') {
                if (registrosSelected.length > 0) {
                    const processarRegistros = async () => {
                        for (const [index, obj] of registrosSelected.entries()) {
                            // Aguarda 3 segundos antes de continuar para o próximo registro
                            await new Promise((resolve) => setTimeout(resolve, 3000));
                            // Descomente a linha abaixo se quiser chamar a API após cada log
                            // await api.get('/GerarBoletoBanco/' + obj.id_cliente, obj.valor, obj.id_emitente, obj.id);
                        }
                        handleClose();
                    };
                    processarRegistros();
                }
            }
        } else {
            toast.warning("Favor selecionar emitente e o mês..");
            handleClose();
            return;
        }
    }

    const handleGerarPagar = async () => {
        if (selectedEmitente && mes) {
            try {
                const obj = {
                    'id_emitente': selectedEmitente,
                    'mes': mes
                }
                const { data: gerando } = await api.post("/contasPagarGeracao/gerar/" + selectedEmitente, obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                toast.success('Contas a pagar gerada com sucesso...');
                handleClose();
            } catch (error) {
                toast.error("Problema na geração: " + error);
                handleClose();
            }
        } else {
            toast.warning("Favor selecionar emitente e o mês..");
            handleClose();
            return;
        }
    }

    function formatDate(inputDate) {
        // Dividir a string de entrada no formato dd/mm/yyyy
        let [day, month, year] = inputDate.split('/');

        // Obter a data atual para extrair horas, minutos e segundos
        let currentDate = new Date();
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Retornar a data no novo formato yyyy-mm-dd HH:mm:ss
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleInserirNFSe = async (clienteId, valor, emitenteId, idFaturamento) => {
        if (clienteId !== "" && clienteId !== "" && cidades !== "" && servicos !== "" && descricao !== "") {
            try {
                const valorFormatado = parseFloat(valor.replace(',', '.'));
                const obj =
                {
                    id_emitente: emitenteId,
                    id_cliente: clienteId,
                    id_servico: selectedServico,
                    id_cidade: selectedCidade,
                    numero: 123,
                    valor: valorFormatado,
                    descricao: descricao,
                    data: formatDate(data),
                    status: 'Pendente',
                    id_faturamento: idFaturamento
                }
                const numero = await api.post("/createNfse", obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });

            } catch (error) {
                toast.error('Erro ao incluir os dados:', error);
            }
        } else {
            toast.error("Todos os campos devem ser preenchidos..")
        }
    }

    const handleEmitirNFSe = () => {
        setLoading(true);
        registrosSelected.map((obj) => {
            handleInserirNFSe(obj.id_cliente, obj.valor, obj.id_emitente, obj.id);
        });
        setTimeout(() => {
            emitirNFSe();
            handleClose();
        }, 3000);
    }

    // Select emitentes
    useEffect(() => {
        (async () => {
            try {
                const { data: emitentes } = await api.get("/emitentesAll/" + acessoContador + "/" + acesso, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (emitentes) {
                    setEmitentes(emitentes);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, []);

    // Select servicos
    useEffect(() => {
        (async () => {
            try {
                const { data: servicos } = await api.get("/servicos", {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (servicos) {
                    setServicos(servicos);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, []);

    // Select cidades
    useEffect(() => {
        (async () => {
            try {
                const { data: cidades } = await api.get("/cidades", {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (cidades) {
                    setCidades(cidades);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, []);

    return (
        <div>
            <Dialog open={statusModal} disableEscapeKeyDown={true} onClose={handleClose} scroll="paper" fullWidth>

                {operacao === 'Geracao' && (
                    <>
                        <DialogTitle>Geração de faturamento</DialogTitle>
                        <DialogContent dividers>
                            <label>Emitente : </label>
                            <select className='select-item'
                                onChange={(e) => setSelectedEmitente(e.target.value)}
                                required
                                value={selectedEmitente}
                            >
                                <option key='99999' value='99999'>Escolha um Emitente</option>
                                {
                                    emitentes.map((emit) => (
                                        <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                                    ))
                                }
                            </select>
                            <hr></hr>
                            <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mes}
                                    label="Mes"
                                    onChange={handleMes}
                                    style={{ width: 300, margin: 10 }}
                                >
                                    <MenuItem disabled value="escolha"><em>Escolha um mês para geração</em></MenuItem>
                                    <MenuItem value='atual'>Mês atual</MenuItem>
                                    <MenuItem value='proximo'>Próximo mês</MenuItem>
                                </Select>

                                <TextField style={{ margin: 10, padding: 5 }}
                                    id="diaGeracao"
                                    label="Dia para geração"
                                    type="number"
                                    value="0"
                                    // defaultValue={primeiroDiaString}
                                    // onChange={(e) => handlePrimeiraData(e.target.value)}
                                    variant="standard"
                                    className="geracao-custom-textfield"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div>
                                {loading ? <img src={loadingGif} alt="Loading..." /> : (
                                    <>
                                        <button className="btn-fat-fechar" style={{ marginRight: 5 }} onClick={handleClose}>Fechar</button>
                                        <button className="btn-fat-gerar" onClick={handleGerar}>Gerar</button>
                                    </>
                                )}
                            </div>

                            {/* {operacao === 'Editar' && (
                    <button className="btn-incluir" onClick={handleUpdate}>Alterar</button>
                )}
                {operacao !== 'Editar' && (
                    <button className="btn-incluir" onClick={handleInserir}>Gravar</button>
                )} */}
                        </DialogActions>
                    </>
                )}

                {operacao === 'Boleto' && (
                    <>
                        <DialogTitle>Emissão de Boletos</DialogTitle>
                        <DialogContent dividers>
                            <label>Emitente : </label>
                            <select className='select-item'
                                onChange={(e) => setSelectedEmitente(e.target.value)}
                                required
                                value={selectedEmitente}
                            >
                                <option key='99999' value='99999'>Escolha um Emitente</option>
                                {
                                    emitentes.map((emit) => (
                                        <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                                    ))
                                }
                            </select>
                            <hr></hr>
                            <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mes}
                                    label="Mes"
                                    onChange={handleMes}
                                    style={{ width: 300, margin: 10 }}
                                >
                                    <MenuItem disabled value="escolha"><em>Escolha um mês para geração</em></MenuItem>
                                    <MenuItem value='atual'>Mês atual</MenuItem>
                                    <MenuItem value='proximo'>Próximo mês</MenuItem>
                                </Select>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div>
                                {loading ? <img src={loadingGif} alt="Loading..." /> : (
                                    <>
                                        <button className="btn-fat-fechar" style={{ marginRight: 5 }} onClick={handleClose}>Fechar</button>
                                        <button className="btn-fat-gerar" onClick={handleGerar}>Gerar</button>
                                    </>
                                )}
                            </div>
                        </DialogActions>
                    </>
                )}

                {operacao === 'NFSe' && (
                    <>
                        <DialogTitle>Emissão de NFS-e</DialogTitle>
                        <DialogContent dividers>
                            <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mes}
                                    label="Mes"
                                    onChange={handleMes}
                                    style={{ width: 300, margin: 10 }}
                                >
                                    <MenuItem disabled value="escolha"><em>Escolha um mês para geração</em></MenuItem>
                                    <MenuItem value='atual'>Mês atual</MenuItem>
                                    <MenuItem value='proximo'>Próximo mês</MenuItem>
                                </Select>
                            </div>
                        </DialogContent>
                        <hr></hr>
                        <DialogContent className="dl-container-nfse">
                            <label>Serviço : </label>
                            <select className='select-item'
                                onChange={(e) => setSelectedServico(e.target.value)}
                                required
                                value={selectedServico}
                            >
                                <option key='99999' value='99999'>Escolha um Serviço</option>
                                {
                                    Array.isArray(servicos) && servicos.length > 0 ? (
                                        servicos.map((cnae) => (
                                            <option key={cnae.id} value={cnae.id}>{cnae.codigo} - {cnae.descricao}</option>
                                        ))
                                    ) : (
                                        <option>Carregando...</option>
                                    )
                                }
                            </select>
                        </DialogContent>
                        <DialogContent className="dl-container-nfse">
                            <label>Local  : </label>
                            <select className='select-item'
                                onChange={(e) => setSelectedCidade(e.target.value)}
                                required
                                value={selectedCidade}
                            >
                                <option key='99999' value='99999'>Escolha uma Cidade</option>
                                {
                                    Array.isArray(cidades) && cidades.length > 0 ? (
                                        cidades.map((cid) => (
                                            <option key={cid.codigo_ibge} value={cid.id}>{cid.nome}</option>
                                        ))
                                    ) : (
                                        <option>Carregando...</option>
                                    )
                                }
                            </select>
                        </DialogContent>
                        <hr></hr>
                        <DialogContent className="dl-container-nfse" dividers>
                            <TextField
                                fullWidth
                                required
                                value={descricao}
                                id="descricao"
                                label="Descrição do serviço"
                                variant="filled"
                                // value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <div>
                                {loading ? <img src={loadingGif} alt="Loading..." /> : (
                                    <>
                                        <button className="btn-fat-fechar" style={{ marginRight: 5 }} onClick={handleClose}>Fechar</button>
                                        <button className="btn-fat-gerar" onClick={handleEmitirNFSe}>Gerar</button>
                                    </>
                                )}
                            </div>
                        </DialogActions>
                    </>
                )}

                {operacao === 'GeracaoPagar' && (
                    <>
                        <DialogTitle>Geração de contas a pagar</DialogTitle>
                        <DialogContent dividers>
                            <label>Emitente : </label>
                            <select className='select-item'
                                onChange={(e) => setSelectedEmitente(e.target.value)}
                                required
                                value={selectedEmitente}
                            >
                                <option key='99999' value='99999'>Escolha um Emitente</option>
                                {
                                    emitentes.map((emit) => (
                                        <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                                    ))
                                }
                            </select>
                            <hr></hr>
                            <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mes}
                                    label="Mes"
                                    onChange={handleMes}
                                    style={{ width: 300, margin: 10 }}
                                >
                                    <MenuItem disabled value="escolha"><em>Escolha um mês para geração</em></MenuItem>
                                    <MenuItem value='atual'>Mês atual</MenuItem>
                                    <MenuItem value='proximo'>Próximo mês</MenuItem>
                                </Select>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div>
                                {loading ? <img src={loadingGif} alt="Loading..." /> : (
                                    <>
                                        <button className="btn-fat-fechar" style={{ marginRight: 5 }} onClick={handleClose}>Fechar</button>
                                        <button className="btn-fat-gerar" onClick={handleGerarPagar}>Gerar</button>
                                    </>
                                )}
                            </div>
                        </DialogActions>
                    </>
                )}

            </Dialog>
        </div>

    )
}

export default ModalGeracao;