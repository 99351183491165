import { useState, useEffect } from 'react';
import api from '../../api.js';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { decryptToken, encryptToken } from '../../utils/criptografia.js';

const ClienteServico = ({ statusModal, onCloseModal, selectedId }) => {

    const [servicos, setServicos] = useState([]);
    const [servicosClientes, setServicosClientes] = useState([]);
    const [valor, setValor] = useState(0);
    const [dia, setDia] = useState(0);
    const [selectedServico, setSelectedServico] = useState(0);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const columns = [
        { field: 'Descricao', headerName: 'Descrição', width: 140 },
        { field: 'Valor', headerName: 'Valor', width: 300, flex: 1, },
        { field: 'DiaCobranca', headerName: 'Dia', width: 100, flex: 1 },
        {
            field: 'deletar',
            headerName: '',
            width: 120,
            renderCell: (params) => (
                <button className='btn_excluir' onClick={() => handleButtonExcluir(params.id)}>
                    Excluir
                </button>
            )
        }
    ];

    const handleClose = () => {
        setDia(0);
        setValor(0);
        setSelectedServico(0);
        onCloseModal();
    }

    const handleAtualizaServico = async () => {
        try {
            const { data: servicoscli } = await api.get("/clienteServico/" + selectedId, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (servicoscli) {
                setServicosClientes(servicoscli);
            }
            setDia(0);
            setValor(0);
            setSelectedServico(0);
        } catch (error) {
            alert(error);
        }
    }

    const handleButtonExcluir = async (id) => {
        const exclui = await api.delete('/clienteServico/' + id, {
            headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
        });
        handleAtualizaServico();
    }

    const handleServico = (e) => {
        setSelectedServico(e);
    }

    // Select servicos
    useEffect(() => {
        (async () => {
            try {
                const { data: servicos } = await api.get("/servicos-gone", {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (servicos) {
                    setServicos(servicos);
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, []);

    // Select servicos do cliente atual
    useEffect(() => {
        (async () => {
            try {
                const { data: servicoscli } = await api.get("/clienteServico/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (servicoscli) {
                    setServicosClientes(servicoscli);
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, [selectedId]);

    const handleIncluir = async () => {
        if (dia === 0) { toast.warning('Favor informar o dia do vencimento'); return; }
        if (valor === 0) { toast.warning('Favor informar o valor.'); return; }
        if (!selectedServico) { toast.warning('Favor escolher um servico'); return; }

        const obj = {
            idCliente: selectedId,
            idServico: selectedServico,
            dia: dia,
            valor: valor.replace(',', '.')
        }
        try {
            const novo = await api.post("/clienteServico", obj, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });

        } catch (error) {
            alert(error);
        }
        handleAtualizaServico();
    }
    return (
        <div>
            <Dialog className="dxcli-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Cliente / Serviços</DialogTitle>
                <DialogContent className="cli-container" dividers>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", width: 650 }}>
                        <Select
                            labelId="servico-cliente"
                            id="servico-cliente"
                            value={selectedServico}
                            variant="standard"
                            style={{ width: 200 }}
                            label="Serviço: "
                            onChange={(e) => handleServico(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Selecione um serviço</MenuItem>
                            {servicos && servicos.length > 0 && servicos.map((serv) =>
                                <MenuItem key={serv.id} value={serv.id}>{serv.Descricao}</MenuItem>
                            )}
                        </Select>

                        <TextField
                            id="dia-vencto"
                            label="Dia Vencimento: "
                            style={{ width: 100 }}
                            variant="standard"
                            margin="dense"
                            type='number'
                            value={dia}
                            onChange={(e) => setDia(e.target.value)}
                        />
                        <TextField
                            style={{ width: 100 }}
                            id="valor"
                            label="Valor: "
                            variant="standard"
                            margin="dense"
                            type='decimal'
                            value={valor}
                            onChange={(e) => setValor(e.target.value)}
                        />
                        <button onClick={handleIncluir} style={{ width: 80, backgroundColor: 'blue', borderRadius: 10, border: 'none', height: 30, margin: '10', color: '#fff', cursor: 'pointer' }}>
                            Incluir
                        </button>
                    </div>
                    <br></br>
                    <div className="div-servicos">
                        <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                            <DataGrid
                                autoHeight
                                rows={servicosClientes ?? []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20, 50]}
                                disableRowSelectionOnClick={false} // Permite a seleção ao clicar na linha
                            // rowSelectionModel={['id']} // Substitua por um array com os IDs das linhas que deseja selecionar
                            />
                        </Box>
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    {/* <button className="btn-incluir" onClick={selectedId ? handleUpdate : handleInserir}>Salvar</button> */}
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ClienteServico;