import './Usuarios.css';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import api from '../../api.js';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import ModalUsuarios from '../Modal/Modal-usuarios.jsx';
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../../routes.jsx';

function Usuarios() {

    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('sessionToken'));
    const [idEmitente, setIdEmitente] = useState(localStorage.getItem('emitenteId'));
    const [idContador, setIdContador] = useState(localStorage.getItem('sessionContador'));

    const { user } = useContext(AuthContext);

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 60,

            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonEditar(params.id)}
                    title='Editar'
                />
            )
        },
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'nome', headerName: 'Nome', width: 170 },
        { field: 'email', headerName: 'Email', width: 500, flex: 1 },
        // { field: 'password', headerName: 'Senha', width: 100, renderCell: (params) => '••••••••', },
        { field: 'administrador', headerName: 'Admin', width: 70 },
        {
            field: 'deletar',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonExcluir(params.id)}
                    title='Excluir'
                />
            )
        }
    ];

    function handleButtonExcluir(id) {
        //alert(id);
    }

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        api.get("/users/" + idEmitente + "/" + idContador,
            {
                headers: {
                    Authorization: `Bearer ${token}` // Envia o token no cabeçalho
                }
            }).then(({ data }) => {
                if (data) {
                    setUsuario(data);
                }
            }).catch((err) =>
                toast.error("ops! ocorreu um erro" + err));
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/users/" + idEmitente + "/" + idContador,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (data) {
                    setUsuario(data);
                }
            } catch (e) {
                toast.error("Problema na rotina...: " + e);
            }
        })();
    }, []);

    return (
        <div className="container-servicos">
            <ModalUsuarios statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalUsuarios>

            <div className="container-top">
                <div className="column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Usuários</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="servico-meio">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={usuario ?? []}
                        columns={columns}
                        disableColumnResize={true}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false
                                },
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    );
}

export default Usuarios;