import CryptoJS from 'crypto-js';
const SECRET_KEY = 'XYZ12345678'; // Mantenha isso seguro

// Função para criptografar o token
export function encryptToken(token) {
    return CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
}

// Função para decriptar o token
export function decryptToken(encryptedToken) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}
