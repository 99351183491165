
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-faturamento.css';
import { useEffect, useState } from "react";
import api from "../../api";
import { decryptToken, encryptToken } from '../../utils/criptografia.js';

function ModalFaturamento({ statusModal, onCloseModal, operacao, selectedId, emitentes }) {

    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [selectedCliente, setSelectedCliente] = useState(0);
    const [descricao, setDescricao] = useState("");
    const [valor, setValor] = useState(0);
    const [vencimento, setVencimento] = useState("");
    const [boleto, setBoleto] = useState("");
    const [nfse, setNfse] = useState("");
    const [clientes, setClientes] = useState([]);
    const [tokenSession, setTokenSession] = useState(decryptToken(localStorage.getItem('sessionToken')));

    const acessoContador = localStorage.getItem("sessionContador");

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) {
                    // nesse caso é cliente novo
                    return;
                }
                const data = await api.get("/faturamentoId/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (!data.data) return;
                const newToken = data.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', encryptToken(newToken));
                    setTokenSession(encryptToken(newToken));
                }
                if (data.data[0]) {
                    const obj = (data.data[0]);
                    const { id_emitente, id_cliente, descricao, valor, vencimento } = obj;
                    // carregar a tabela de cliente inteira senão não consigo selecionar o cliente desse faturamento
                    const { data: cli } = await api.get("/clientes/Todos/" + id_emitente, {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                    if (cli) {
                        setClientes(cli);
                    }
                    setValor(valor);
                    setDescricao(descricao);
                    setSelectedEmitente(id_emitente === 0 ? 0 : id_emitente);
                    setSelectedCliente(id_cliente === 0 ? 0 : id_cliente);
                    const dt = new Date(vencimento);
                    // Obtenha o dia, mês e ano
                    const dia = String(dt.getUTCDate()).padStart(2, '0');
                    const mes = String(dt.getUTCMonth() + 1).padStart(2, '0'); // Janeiro é 0, então adicionamos 1
                    const ano = dt.getUTCFullYear();
                    // Formate a data no estilo 'YYYY-MM-DD'
                    const dataFormatada = `${ano}-${mes}-${dia}`;
                    setVencimento(dataFormatada);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);


    const handleClose = () => {
        setValor(0);
        setDescricao("");
        setSelectedCliente(0);
        setSelectedEmitente(0);
        setVencimento("");
        onCloseModal();
    }

    const handleUpdate = async (id) => {
        try {
            if (selectedCliente && selectedEmitente && valor > 0 && descricao != '' && vencimento != '') {
                const obj = {
                    "emitente": selectedEmitente,
                    "cliente": selectedCliente,
                    "descricao": descricao,
                    "valor": valor,
                    "vencimento": vencimento
                };
                await api.post('/faturamento/' + selectedId, obj,
                    {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                toast.success('Update efeturado com sucesso');
                handleClose();
            } else {
                toast.warning('Favor preencher todos os dados.');
            }

        } catch (error) {
            toast.warning('Favor informar todos os campos.');
        }
    }

    const handleInserir = async () => {
        try {
            if (selectedCliente && selectedEmitente && valor > 0 && descricao != '' && vencimento != '') {
                const obj = {
                    "emitente": selectedEmitente,
                    "cliente": selectedCliente,
                    "descricao": descricao,
                    "valor": valor,
                    "vencimento": vencimento
                };
                await api.post('/faturamento/novo', obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                toast.success('Faturamento incluido com sucesso.');
                handleClose();
            } else {
                toast.warning('Favor informar todos os campos.');
            }
        } catch (error) {

        }
    }

    const handleEmitente = async (ev) => {
        setSelectedEmitente(ev);
        const { data: cli } = await api.get("/clientes/Todos/" + ev, {
            headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
        });
        if (cli) {
            setClientes(cli);
        } else { toast.warning('nao achei cliente') }

    }

    return (
        <div>
            <Dialog open={statusModal} onClose={handleClose} scroll="paper" fullWidth>
                <DialogTitle>Controle de faturamento</DialogTitle>
                <DialogContent dividers>
                    <label>Emitente : </label>
                    <select className='select-item'
                        onChange={(e) => handleEmitente(e.target.value)}
                        required
                        value={selectedEmitente}
                    >
                        <option key='99999' value='99999'>Escolha um Emitente</option>
                        {
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <label>Cliente : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedCliente(e.target.value)}
                        required
                        value={selectedCliente}
                    >
                        <option key='99999' value='99999'>Escolha um Cliente</option>
                        {
                            clientes.map((cli) => (
                                <option key={cli.id} value={cli.id}>{cli.nome}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        required
                        value={descricao}
                        id="descricao"
                        label="Descrição"
                        variant="filled"
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            required
                            type="number"
                            value={valor}
                            id="valor"
                            label="Valor"
                            variant="filled"
                            onChange={(e) => setValor(e.target.value)}
                        />
                        <TextField
                            required
                            type="date"
                            value={vencimento}
                            id="vencimento"
                            label="Vencimento"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setVencimento(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            type="text"
                            value={boleto}
                            id="boleto"
                            label="N.Boleto"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            value={nfse}
                            id="nfse"
                            label="NFSe"
                            variant="standard"
                        />
                    </div>

                </DialogContent>
                <hr></hr>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    {operacao === 'Editar' && (
                        <button className="btn-incluir" onClick={handleUpdate}>Alterar</button>
                    )}
                    {operacao === 'Incluir' && (
                        <button className="btn-incluir" onClick={handleInserir}>Salvar</button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalFaturamento;